<template>
  <v-container class="point-mirror-adjustment-by-manager">
    <v-row v-if="hasDriver">
      <v-col class="mb-8">
        <span class="ajustment-title">{{ getDriver.name }}</span>
        <span class="ajustment-subtitle mx-2">{{ formatAdmissionDate }}</span>
        <br>
        <span class="ajustment-subtitle">{{ getDriver.cpf }}</span>
      </v-col>
    </v-row>
    <v-row
      v-if="hasDriver"
      no-gutters
    >
      <v-col
        cols="4"
      >
        <v-row class="ajustment-title">
          <v-col cols="12">
            {{ $t('components.lists.drivers.adjustment.manager.title') }}
          </v-col>
        </v-row>
        <v-row
          v-for="(event, index) in getWorkday.events"
          :key="event.id"
          :index="index"
          style="max-width:200px;"
        >
          <v-col align-self="center">
            <span>
              <strong class="mirror-hour">{{ formatDateTimeWithTimezone(event.start_time) }}</strong>
              <br>
              <span>{{ $t(`components.lists.mirrors.${event.kind}`) }}</span>
            </span>
            <hr
              v-if="isLastLine(index)"
              class="mt-3"
            >
          </v-col>
        </v-row>
        <v-row style="max-width:200px;">
          <v-col cols="12">
            <v-select
              v-model="reasonSelect"
              :items="getAdjustmentReasons"
              item-text="name"
              item-value="id"
              class="advertence-select mt-6"
              :label="$t('components.lists.drivers.adjustment.reason')"
              outlined
            />
          </v-col>
          <v-col>
            <v-btn
              class="button mt-n12 py-6"
              color="accent"
              width="100%"
              large
              :disabled="!lastIsOvernight"
              @click="sendWorkdayUpdate"
            >
              {{ $t('components.lists.drivers.adjustment.manager.to_save') }}
            </v-btn>

            <span
              v-if="!lastIsOvernight"
              class="text-center d-block red--text font-weight-black"
            >
              {{ $t('components.lists.drivers.adjustment.manager.form.error_overnight') }}
            </span>
          </v-col>
        </v-row>
      </v-col>
      <v-col>
        <adjustment-manager-form
          :workday="getWorkday"
          :driver="getDriver"
          :adjustment-reasons="getAdjustmentReasons"
          @changeWorkdayToDayOff="changeWorkdayToDayOff"
          @updateDsrSuppressed="updateDsrSuppressed"
          @events="saveEvents"
          @orderEvents="orderEvents"
        />
      </v-col>
    </v-row>
    <div
      v-else
      class="text-center accent--text font-weight-black"
    >
      <v-icon
        color="accent"
        size="25"
      >
        mdi-alert-circle
      </v-icon>
      {{ $t('views.point_mirror_adjustment.not_found') }}
    </div>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import formats from '@/mixins/formats';
import adjustment from '@/mixins/adjustment';
import AdjustmentManagerForm from '@/components/forms/adjustment/manager/AdjustmentManagerForm.vue';

export default {
  name: 'PointMirrorAdjustmentByManager',
  components: {
    AdjustmentManagerForm,
  },
  mixins: [formats, adjustment],
  data: () => ({
    events: [],
    reasonSelect: null,
  }),
  computed: {
    ...mapGetters([
      'getWorkday',
      'getDriver',
      'getAdjustmentReasons',
    ]),

    hasDriver() {
      return this.getDriver.id != null && this.getWorkday.id != null;
    },

    formatAdmissionDate() {
      return this.formatDate(this.getDriver.admission_date);
    },

    lastIsOvernight() {
      const hasOvernight = this.events.find((event) => event.kind === 'overnight');

      if (!hasOvernight) return true;

      return this.events[this.events.length - 1].kind === 'overnight';
    },
  },
  watch: {
    getWorkday() {
      this.initCurrentBreadcrumbs([
        {
          text: this.$i18n.t('breadcrumbs.point_mirror'),
          disabled: false,
          exact: true,
          to: {
            name: 'mirrorList',
          },
        },
        {
          text: this.$i18n.t('breadcrumbs.driver'),
          disabled: false,
          exact: true,
          to: {
            name: 'mirrorListShow',
            params: {
              id: this.$route.params.driverId,
            },
          },
        },
        {
          text: this.$i18n.t('breadcrumbs.details_day'),
          disabled: false,
          exact: true,
          to: {
            name: 'workdayShow',
            params: {
              userId: this.$route.params.driverId,
              workdayId: this.$route.params.workdayId,
            },
          },
        },
        {
          text: this.$i18n.t('breadcrumbs.adjustment'),
          disabled: true,
        },
      ]);
    },
  },
  mounted() {
    this.fetchWorkday({ id: this.$route.params.workdayId });
    this.fetchStoreDriver({ id: this.$route.params.driverId });
    this.fetchAdjustmentReasons({ by_active: true });
  },
  methods: {
    ...mapActions([
      'fetchWorkday',
      'fetchStoreDriver',
      'initLoading',
      'initCurrentBreadcrumbs',
      'fetchAdjustmentReasons',
      'createWorkdayUpdate',
      'turnWorkdayIntoDayOff',
      'changeDsrSuppressed',
    ]),
    isLastLine(index) {
      return index !== this.getWorkday.events.length - 1;
    },
    saveEvents(value) {
      this.events = value;
    },
    changeWorkdayToDayOff(payload) {
      this.turnWorkdayIntoDayOff(payload);
    },
    updateDsrSuppressed(dsrSuppressed) {
      this.changeDsrSuppressed({ dsr_suppressed: dsrSuppressed, id: this.getWorkday.id });
    },
    sendWorkdayUpdate() {
      if (this.reasonSelect) {
        this.orderEvents();
        this.initLoading();
        this.createWorkdayUpdate({
          id: this.getWorkday.id,
          userId: this.getDriver.id,
          events: this.formatEvents(),
          reason_id: this.reasonSelect,
        });
      } else {
        this.$store.dispatch('addErrorMessage', this.$t('errors.adjustment_reason_blank'));
      }
    },
    formatEvents() {
      const eventsFormated = [];

      this.events.forEach((event) => {
        eventsFormated.push({
          kind: event.kind,
          lat: event.lat,
          lng: event.lng,
          time: this.changeEventTimezoneHack(event.start_time),
        });
      });

      return eventsFormated;
    },
    changeEventTimezoneHack(startTime) {
      const timezoneToChange = this.getWorkday.timezone_offset * -1;
      const toReplace = `$1${timezoneToChange}:`;
      // 25 is the position of timezone, bacause of daylight i need to change the timezone. If daylight is not active, this will work "perfectly" :D
      return startTime.replace(/(.{25}).{2}/, toReplace);
    },
    orderEvents() {
      this.events.sort((a, b) => new Date(a.start_time) - new Date(b.start_time));
    },
  },
};
</script>
<style lang="scss" scoped>
.point-mirror-adjustment-by-manager {
  .ajustment-title {
    font-family: 'Fira-Sans-Bold';
    font-size: 20px;
    color: $color_list_header;
  }
  .ajustment-subtitle {
    font-family: 'Acumin-Pro-Regular';
    color: $color_secondary_dark;
  }
  .button {
    font-family: 'Fira-Sans-Bold';
  }
  .mirror-hour {
    color: $color_primary;
    font-size: 22px;
  }
}
</style>
