<template>
  <v-container
    class="lighten-5 ajustment-form pa-0"
  >
    <v-row class="ajustment-title mb-3">
      <v-col cols="12">
        {{ $t('components.lists.drivers.adjustment.manager.form.title') }}
        <v-btn
          color="accent"
          class="mx-4"
          outlined
          @click="orderEvents()"
        >
          {{ $t('components.lists.drivers.adjustment.manager.form.order') }}
        </v-btn>

        <v-btn
          class="text-decoration-underline"
          text
          color="accent"
          @click="openModal()"
        >
          {{ $t('components.lists.drivers.adjustment.manager.form.create_slack') }}
        </v-btn>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col>
        <v-row
          v-for="(event, index) in events"
          :key="event.id"
          :index="index"
          no-gutters
        >
          <v-col>
            <date-time
              :start-time="event.start_time"
              :index="index"
              @datetime="saveDateTime"
            />
          </v-col>
          <v-col
            cols="4"
            class="ml-2"
          >
            <v-select
              v-model="event.kind"
              :items="kinds"
              :label="$t('components.lists.drivers.adjustment.register')"
              color="accent"
              height="42"
              outlined
            />
          </v-col>
          <v-col>
            <v-icon
              class="mx-4"
              style="height:56px;"
              @click="removeEvent(index)"
            >
              mdi-close
            </v-icon>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-checkbox
          v-model="dsrSuppressed"
          :label="$t('components.lists.mirrors.dsr_suppressed')"
          @click="updateDsrSuppressed"
        />
      </v-col>
    </v-row>
    <v-row style="max-width:500px;">
      <v-col>
        <v-btn
          class="button my-8 py-6"
          color="accent"
          width="100%"
          large
          outlined
          @click="createNewRegister()"
        >
          {{ $t('components.lists.drivers.adjustment.manager.form.add_record') }}
        </v-btn>
      </v-col>
    </v-row>

    <confirm-day-off-dialog
      v-model="dayOffDialog"
      :adjustment-reasons="adjustmentReasons"
      @changeWorkdayToDayOff="changeWorkdayToDayOff"
    />
  </v-container>
</template>

<script>
import formats from '@/mixins/formats';
import adjustment from '@/mixins/adjustment';
import DateTime from '@/components/forms/adjustment/manager/DateTime.vue';
import ConfirmDayOffDialog from '@/components/dialogs/ConfirmDayOffDialog.vue';

export default {
  name: 'AdjustmentManagerForm',
  components: {
    DateTime,
    ConfirmDayOffDialog,
  },
  mixins: [formats, adjustment],
  props: {
    workday: {
      type: Object,
      default: () => {},
    },
    driver: {
      type: Object,
      default: () => {},
    },
    adjustmentReasons: {
      type: Array,
      default: () => ([]),
    },
  },
  data: () => ({
    kinds: [],
    events: [],
    reasonSelect: '',
    dayOffDialog: false,
    dsrSuppressed: false,
  }),
  watch: {
    workday(value) {
      this.events = [];

      value.events.forEach((ev) => {
        this.events.push({ ...ev });
      });

      this.dsrSuppressed = value.dsr_suppressed;
    },
    events(value) {
      this.$emit('events', value);
    },
  },
  mounted() {
    ['drive', 'wait', 'available', 'meal', 'rest', 'overnight', 'wait_meal', 'rest_meal', 'wait_rest'].forEach((kind) => {
      this.kinds.push({
        value: kind,
        text: this.$t(`components.lists.mirrors.${kind}`),
      });
    });

    if (this.workday) {
      this.events = [];

      this.workday.events.forEach((ev) => {
        this.events.push({ ...ev });
      });

      this.dsrSuppressed = this.workday.dsr_suppressed;
    }
  },
  methods: {
    removeEvent(index) {
      this.events.splice(index, 1);
    },
    createNewRegister() {
      this.events.push({
        start_time: this.startTimeLastEvent(),
        kind: this.kindLastEvent(),
      });
    },
    saveDateTime(date) {
      this.events[date.index].start_time = date.date;
      this.orderEvents();
      this.$emit('events', this.events);
    },
    startTimeLastEvent() {
      return this.events.length === 0 ? this.workday.created_at : this.events[this.events.length - 1].start_time;
    },
    kindLastEvent() {
      return this.events.length === 0 ? 'overnight' : this.events[this.events.length - 1].kind;
    },
    orderEvents() {
      this.$emit('orderEvents');
    },
    openModal() {
      this.dayOffDialog = true;
    },
    closeModal() {
      this.dayOffDialog = false;
    },
    changeWorkdayToDayOff({ kind, adjustmentId }) {
      this.$emit('changeWorkdayToDayOff', { id: this.workday.id, kind, reason_id: adjustmentId });
    },
    updateDsrSuppressed() {
      this.$emit('updateDsrSuppressed', this.dsrSuppressed);
    },
  },
};
</script>

<style lang="scss" scoped>
.ajustment-form {
  .ajustment-title {
    font-family: 'Fira-Sans-Bold';
    font-size: 20px;
    color: $color_list_header;
  }
  .ajustment-subtitle {
    font-family: 'Acumin-Pro-Regular';
    color: $color_secondary_dark;
  }
  .button {
    font-family: 'Fira-Sans-Bold';
    letter-spacing: 0;
    height: 42px;
    width: 220px;
  }
  .mirror-hour {
    color: $color_primary;
  }
}
</style>
