<template>
  <v-row no-gutters>
    <v-col
      cols="6"
      align-self="center"
    >
      <v-menu
        ref="menu"
        :close-on-content-click="true"
        transition="scale-transition"
        offset-y
        min-width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            :value="dateFormatting"
            :label="translate('date')"
            height="42"
            color="accent"
            readonly
            outlined
            v-bind="attrs"
            v-on="on"
          />
        </template>
        <v-date-picker
          ref="picker"
          v-model="date"
          no-title
          @change="sendDateTime"
        />
      </v-menu>
    </v-col>
    <v-col
      cols="5"
      align-self="center"
      class="ml-3"
    >
      <v-dialog
        ref="dialog"
        v-model="showTimePicker"
        :return-value.sync="time"
        persistent
        width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="time"
            :label="translate('time')"
            height="42"
            color="accent"
            readonly
            outlined
            v-bind="attrs"
            v-on="on"
          />
        </template>
        <v-time-picker
          v-if="showTimePicker"
          v-model="time"
          full-width
          format="24hr"
        >
          <v-spacer />
          <v-btn
            text
            color="primary"
            @click="changeTimeDialog"
          >
            {{ translate('cancel') }}
          </v-btn>
          <v-btn
            text
            color="primary"
            @click="saveTime()"
          >
            {{ translate('ok') }}
          </v-btn>
        </v-time-picker>
      </v-dialog>
    </v-col>
  </v-row>
</template>

<script>
import formats from '@/mixins/formats';

export default {
  name: 'DateTime',
  mixins: [formats],
  props: {
    startTime: {
      type: String,
      default: '',
    },
    index: {
      type: Number,
      default: null,
    },
  },
  data: () => ({
    date: '',
    time: '',
    showTimePicker: false,
  }),
  computed: {
    dateFormatting: {
      get() {
        return this.formatDateISO(this.date);
      },
      set(value) {
        this.date = value;
      },
    },
  },
  mounted() {
    this.setDateTime();
  },
  methods: {
    saveTime() {
      this.$refs.dialog.save(this.time);
      this.sendDateTime();
    },
    setDateTime() {
      this.date = this.startTime;
      this.time = this.formatDateTimeWithTimezone(this.startTime);
    },
    sendDateTime() {
      let newDate = '';
      const date = this.date.split('T');

      if (date.length > 1) {
        const time = date[1].replace(date[1].substring(0, 5), this.time);
        newDate = date[0].concat(`T${time}`);
      } else {
        const startTime = this.startTime.split('T');
        const time = startTime[1].replace(startTime[1].substring(0, 5), this.time);
        newDate = date[0].concat(`T${time}`);
      }
      this.$emit('datetime', { date: newDate, index: this.index });
      this.changeTimeDialog();
    },
    translate(label) {
      return this.$t(`components.lists.drivers.adjustment.manager.form.datetime.${label}`);
    },
    changeTimeDialog() {
      this.showTimePicker = !this.showTimePicker;
    },
  },
};
</script>
