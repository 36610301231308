<template>
  <v-row
    no-gutters
    justify="center"
  >
    <v-dialog
      v-model="dialogOpen"
      max-width="730px"
      :change="closeModal()"
    >
      <v-card>
        <v-card-title class="mb-6">
          <span>
            {{ $t('components.dialogs.confirm_create_day_off.title') }}
          </span>
        </v-card-title>

        <ValidationObserver v-slot="{ valid }">
          <v-card-text>
            <ValidationProvider
              v-slot="{ errors }"
              :name="$t('components.dialogs.confirm_create_day_off.errors.kind')"
              rules="required"
            >
              <v-select
                v-model="kind"
                :items="kinds"
                :label="$t('components.dialogs.confirm_create_day_off.labels.kind')"
                :error-messages="errors"
                color="accent"
                height="42"
                outlined
              />
            </validationprovider>
          </v-card-text>
          <v-card-text>
            <ValidationProvider
              v-slot="{ errors }"
              :name="$t('components.dialogs.confirm_create_day_off.errors.adjustment')"
              rules="required"
            >
              <v-select
                v-model="adjustmentReason"
                :items="adjustmentReasons"
                item-text="name"
                item-value="id"
                :label="$t('components.dialogs.confirm_create_day_off.labels.adjustment')"
                :error-messages="errors"
                color="accent"
                height="42"
                outlined
              />
            </validationprovider>
          </v-card-text>
          <v-card-text class="d-flex justify-end">
            <v-btn
              color="error"
              @click="cancelAction()"
            >
              Cancelar
            </v-btn>
            <v-btn
              class="ml-6"
              color="accent"
              outlined
              :disabled="!valid"
              @click="confirmAction()"
            >
              Confirmar
            </v-btn>
          </v-card-text>
        </ValidationObserver>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  name: 'ConfirmDayOffDialog',
  model: {
    prop: 'dialog',
    event: 'change',
  },
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    adjustmentReasons: {
      type: Array,
      default: () => ([]),
    },
  },
  data() {
    return {
      dialogOpen: false,
      kind: null,
      kinds: [{ value: 'normal', text: 'Folga normal' }, { value: 'cct', text: 'Folga CCT' }, { value: 'dsr', text: 'Folga DSR' }],
      adjustmentReason: null,
    };
  },
  watch: {
    dialog() {
      this.dialogOpen = this.dialog;
    },
  },
  methods: {
    closeModal() {
      this.$emit('change', this.dialogOpen);
    },

    cancelAction() {
      this.dialogOpen = false;

      this.kind = null;
      this.adjustmentReason = null;

      this.$emit('change', this.dialogOpen);
    },

    confirmAction() {
      this.$emit('changeWorkdayToDayOff', { kind: this.kind, adjustmentId: this.adjustmentReason });
    },
  },
};
</script>

<style lang="scss" src="@/assets/scss/components/dialogs/drivers.scss" scoped />
